export const baseCatalog = {
  other: [
    { s: "no" },
    { s: "yes" },
    { s: "please" },
    { s: "thank you" },
    { s: "why" },
    { s: "me" },
    { s: "you" },
    { s: "they" },
  ],
  family: [
    { s: "mam" },
    { s: "dad" },
    { s: "nessie" },
    { s: "nana" },
    { s: "auntie" },
    { s: "grandad" },
  ],
  number: [
    { s: "0" },
    { s: "1" },
    { s: "2" },
    { s: "3" },
    { s: "4" },
    { s: "5" },
    { s: "6" },
    { s: "7" },
    { s: "8" },
    { s: "9" },
    { s: "10", p: "ten" },
    { s: "11", p: "eleven" },
    { s: "12", p: "twelve" },
    { s: "13", p: "thirteen" },
    { s: "14", p: "fourteen" },
    { s: "15", p: "fifteen" },
    { s: "16", p: "sixteenn" },
    { s: "17", p: "seventeen" },
    { s: "18", p: "eighteen" },
    { s: "19", p: "nineteen" },
    { s: "20", p: "twenty" },
    { s: "30", p: "thirty" },
    { s: "40", p: "forty" },
    { s: "50", p: "fifty" },
    { s: "60", p: "sixty" },
    { s: "70", p: "seventy" },
    { s: "80", p: "eighty" },
    { s: "90", p: "ninety" },
    { s: "100", p: "one hundred" },
    { s: "1000", p: "one thousand" },
    { s: "10,000", p: "ten thousand" },
    { s: "100,000", p: "one hundred thousand" },
    { s: "1,000,000", p: "one million" },
  ],
  food: [
    { s: "breakfast" },
    { s: "lunch" },
    { s: "dinner" },
    { s: "supper" },
    { s: "snack" },
    { s: "chips" },
    { s: "crisps" },
    { s: "cereal" },
    { s: "chicken sticks" },
    { s: "apple" },
    { s: "banana" },
    { s: "bread" },
    { s: "cracker" },
    { s: "sweets" },
    { s: "strawberry" },
    { s: "orange" },
    { s: "blueberry" },
    { s: "cheese" },
    { s: "milk" },
    { s: "flour" },
    { s: "sugar" },
    { s: "eggs" },
    { s: "meat" },
  ],
  feeling: [
    { s: "I want" },
    { s: "I do not want" },
    { s: "happy" },
    { s: "sad" },
    { s: "bored" },
    { s: "angry" },
    { s: "frustrated" },
    { s: "hurt" },
    { s: "itchy" },
    { s: "scared" },
    { s: "numb" },
    { s: "I like" },
    { s: "I do not like" },
    { s: "I hate" },
    { s: "I need" },
    { s: "I am" },
    { s: "I am not" },
    { s: "I did" },
    { s: "my" },
    { s: "pee" },
    { s: "poo" },
  ],
  question: [
    { s: "are you" },
    { s: "are we" },
    { s: "am I" },
    { s: "can you" },
    { s: "can we" },
    { s: "can I" },
    { s: "is it" },
    { s: "how many" },
  ],
  time: [
    { s: "today" },
    { s: "tomorrow" },
    { s: "later" },
    { s: "yesterday" },
    { s: "monday" },
    { s: "tuesday" },
    { s: "wednesday" },
    { s: "thursday" },
    { s: "friday" },
    { s: "saturday" },
    { s: "sunday" },
    { s: "christmas" },
    { s: "haloween" },
  ],
  body: [
    { s: "head" },
    { s: "eye" },
    { s: "ear" },
    { s: "nose" },
    { s: "mouth" },
    { s: "cheek" },
    { s: "chin" },
    { s: "neck" },
    { s: "throat" },
    { s: "chest" },
    { s: "torso" },
    { s: "shoulder" },
    { s: "back" },
    { s: "kidneys" },
    { s: "arm" },
    { s: "elbow" },
    { s: "hand" },
    { s: "finger" },
    { s: "fingernail" },
    { s: "thumb" },
    { s: "stomach" },
    { s: "tummy" },
    { s: "hip" },
    { s: "bum" },
    { s: "willy" },
    { s: "balls" },
    { s: "leg" },
    { s: "knee" },
    { s: "ankle" },
    { s: "foot" },
    { s: "toe" },
    { s: "toenail" },
  ],
  place: [
    { s: "home" },
    { s: "woods" },
    { s: "river" },
    { s: "diane's house" },
    { s: "nana's house" },
    { s: "school" },
    { s: "park" },
    { s: "shop" },
    { s: "car" },
    { s: "walk" },
  ],
  shape: [
    { s: "angle" },
    { s: "side" },
    { s: "point" },
    { s: "line" },
    { s: "triangle" },
    { s: "square" },
    { s: "rectangle" },
    { s: "diamond" },
    { s: "rhombus" },
    { s: "quadrilateral" },
    { s: "pentagon" },
    { s: "hexagon" },
    { s: "heptogon" },
    { s: "octogon" },
    { s: "nonogon" },
    { s: "decagon" },
    { s: "polygon" },
    { s: "star" },
    { s: "heart" },
  ],
  colour: [
    { s: "black" },
    { s: "white" },
    { s: "red" },
    { s: "green" },
    { s: "blue" },
    { s: "yellow" },
    { s: "pink" },
    { s: "orange" },
    { s: "purple" },
    { s: "gold" },
    { s: "silver" },
  ],
  clothes: [
    { s: "jacket" },
    { s: "trousers" },
    { s: "vest" },
    { s: "t-shirt" },
    { s: "top" },
    { s: "jumper" },
    { s: "hoody" },
    { s: "nappy" },
    { s: "underpants" },
    { s: "socks" },
    { s: "shoes" },
    { s: "wellies" },
    { s: "slippers" },
    { s: "hat" },
    { s: "scarf" },
    { s: "gloves" },
  ],
  space: [
    { s: "sun" },
    { s: "mercury" },
    { s: "venus" },
    { s: "earth" },
    { s: "mars" },
    { s: "jupiter" },
    { s: "saturn" },
    { s: "uranus" },
    { s: "neptune" },
  ],
};
